<div class="golriver-website-credits">
	<img src="assets/img/credits.png" alt="crédits" class="credits-icon">
	<br/>
	<h2>Développeurs :</h2>
	<p>LaChenillePend - <a href="https://gitlab.com/rajoharisonm" target="_blank">rajoharisonm</a></p>
	<p>Narkotiqu - <a href="https://github.com/mal0andre" target="_blank">mal0andre</a></p>
	<p>MAT06Mat - <a href="https://github.com/MAT06mat" target="_blank">MAT06mat</a></p>
	<h2>Rédacteurs/rices :</h2>
	<p>Leeliichou59</p>
	<p>Narkotiqu - <a href="https://github.com/mal0andre" target="_blank">mal0andre</a></p>
	<p>LaChenillePend - <a href="https://gitlab.com/rajoharisonm" target="_blank">rajoharisonm</a></p>
	<h2>Graphisme :</h2>
	<p>Narkotiqu - <a href="https://github.com/mal0andre" target="_blank">mal0andre</a></p>
	<p><a href="https://minecraft.wiki/" target="_blank">Minecraft Wiki</a></p>
</div>